import React, { useState , useEffect} from "react";
import MainHeader from '../MainHeader';
import SideMenu from '../SideMenu';
import { useNavigate} from 'react-router-dom';
import {getStates, createUser, getselectedUser} from "../../utils/reducer";
import {showLoader, hideLoader } from "../../redux/reducers/Actions";
import { useDispatch } from 'react-redux';
import Loader from "../Loader";
import PopupMessage from "../PopupMessage";
import SweetAlert from 'react-bootstrap-sweetalert'; 
const CreateUserStateLicenses = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonType , setButtonType] = useState('add');
  const [editIndex, setEditIndex] = useState('');
  const [NMLSLicense , setNMLSLicense] = useState(props.finalData?.userInfo?.nmlsLicense || '');
  const [inputErrors,setInputsErrors] = useState({});
  const [newLIcense, setNewLicense]= useState({licenseNumber : '', expirationDate : '', state : ''});
  const [states, setStates] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const handleChange = (e) => {
    console.log(e.target.value);
    if(e.target.name === 'expirationDate'){
      
      let dateVal = e.target.value;
      // let dateSplit = dateVal.split("-");
      // const currentYear = new Date().getFullYear();
      // let checkYearFormat = (dateSplit[0].length >= 5 || dateSplit[0] < currentYear) ? '' : dateVal;
      
      // let dateJoin = dateSplit;
      setNewLicense((prev) => ({
        ...prev,
        [e.target.name]: dateVal,
      }));
      
    }else{
    setNewLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }));
  }  
  };
  const handleNavigateCancel = ()=>{
    navigate('/usermanagement');
  }

  useEffect(() => {
    getStates().then(function (response) {
      if (response?.result) {
         setStates(response?.result)
      }
      }).catch((err) => {
        console.error.bind("my account error", err);
      })
  }, []);



  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleAddLicense =()=>{
    const errors = {};
    const fieldsToCheck = {
         licenseNumber : 'licenseNumber', expirationDate : 'expirationDate', state : 'state'
    };

    for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
       if (!newLIcense[fieldName]) {
         errors[fieldName] = `Please Enter ${fieldLabel} `;
       }
    };

    setInputsErrors(errors);

    if (Object.keys(errors).length === 0){
        props?.setLicenseData([...props.licenseData, newLIcense]);
        setNewLicense({licenseNumber : '', expirationDate : '', state : ''});
        setButtonType('add');
        setEditIndex('');
        // setNMLSLicense('')
    }
    
  };

  const handleEdit =(index)=>{
    setButtonType ('update');
    setInputsErrors({});
    setEditIndex(index);
    setNewLicense(props.licenseData[index]);
  };

  const handleDelete = ()=>{
    const updatedlicenseData = props.licenseData.filter((_, i) => i !== editIndex);
    props.setLicenseData(updatedlicenseData);
    setNewLicense({licenseNumber : '', expirationDate : '', state : ''});
    setShowConfirmation(false);
    setButtonType('add');
    setInputsErrors({});
    setEditIndex('');
  };

  const handleUpdateLicense=()=>{
    const errors = {};
    const fieldsToCheck = {
        licenseNumber : 'licenseNumber', expirationDate : 'expirationDate', state : 'state'
    };

    for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
       if (!newLIcense[fieldName]) {
         errors[fieldName] = `Please Enter ${fieldLabel} `;
       }
    };

    setInputsErrors(errors);

    if (Object.keys(errors).length === 0){
        const updatedLicenseData = [...props.licenseData];
        updatedLicenseData[editIndex] = newLIcense;
        setNewLicense({licenseNumber : '', expirationDate : '', state : ''});
        props.setLicenseData(updatedLicenseData);
        setButtonType('add');
        setEditIndex('');
        // setNMLSLicense('')
    }
    
    
  };

  const handleNext=()=>{
    // props.setCurrentPage('loanAssignMent');
    
    // props.setFinalData(prevState => ({...prevState, stateLicenses: {
    //   NMLSLicense: NMLSLicense, // or just NMLSLicense if using shorthand
    //   Licenses: props.licenseData
    // },}));
    props.setFinalData(prevState => {
      const updatedData = {...prevState,
        userInfo : {...prevState?.userInfo, nmlsLicense :NMLSLicense},
        stateLicenses: {
        NMLSLicense: NMLSLicense, // or just NMLSLicense if using shorthand
        Licenses: props.licenseData
      },};
      // Now call createUser with the updatedData
      dispatch(showLoader());
      createUser(updatedData).then(response => {
          // Perform actions after successful creation
          if (!response?.result?.error) {
            if(updatedData?.userid){

                getselectedUser(updatedData?.userid).then(function (response) {
                    if (response?.statusCode == 200) {
                      props.setFinalData(response?.result?.[0]);
                    }
                    }).catch((err) => {
                      console.error.bind("my account error", err);
                    })
            }
              props.setCurrentPage('loanAssignMent');
          }
          dispatch(hideLoader());
      }).catch(err => {
          console.error(err);
          dispatch(hideLoader());
          // Handle error
      });

      return updatedData; // Return the new state
  });
  };

  const getTodayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
console.log('props.licenseData',props.licenseData);

const closePopup = () => {
  setPopupOpen(false);
};
const closeConfirmation = () => {
  setShowConfirmation(false);
};

  return (
    <div id="layout-wrapper">
      {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closeConfirmation}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-license-yes',
                      className: 'btn btn-primary',
                      onClick: handleDelete,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-license-no',
                      className: 'btn btn-light',
                      onClick: closeConfirmation,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this License?</p>
            </SweetAlert>}
            {isPopupOpen && (
            <PopupMessage
             type={'success'}
             message={'Deleted Successfully'}
             onClose={closePopup}
            />
        )}
      
      <MainHeader/>
      <SideMenu />

      <div className="main-content">
        <div className="page-content user_management">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">{props?.isEdit ? 'Update' : 'Create'} User</h4>
                  <a href="#" id="navigateStateLicenses" onClick={(e) => {e.preventDefault(); props.handleBack()}} className="back_btn">
                    <span className="material-icons icon"> arrow_back</span>BACK
                  </a>
                </div>
                <div className="steps">
                  <div className="box bx_progress" onClick={(e) => {props.setCurrentPage('userinfo')}}>
                    <div className="fill" style={{ width: "100%" }}></div>
                    <span className="text">User Info</span>
                    <img
                      src="assets/images/icons/User-Info.svg"
                      className="img"
                      alt=""
                    />
                  </div>
                  <div className="box bx_progress " onClick={(e) => {props.setCurrentPage('securityInfo')}}>
                    <div className="fill" style={{ width: "100%" }}></div>
                    <span className="text">Security Info</span>
                    <img
                      src="assets/images/icons/Security-Info.svg"
                      className="img"
                      alt=""
                    />
                  </div>
                  <div className="box bx_progress active">
                    <span className="text">State Licenses</span>
                    <img
                      src="assets/images/icons/StateLicenses.svg"
                      className="img"
                      alt=""
                    />
                  </div>
                  <div className="box" onClick={(e) => {props.setCurrentPage('loanAssignMent')}}>
                    <span className="text">My Default Loan Assignments</span>
                    <img
                      src="assets/images/icons/LoanAssignments.svg"
                      className="img"
                      alt=""
                    />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body ">
                    <div className="form_section status">
                      <h3 className="title">STATE LICENSES</h3>
                      <div className="sl_block">
                        <div className="left">
                          <div className="hed">
                            <div className="form-group mb-0">
                              <label>NMLS License #</label>
                              <input id="NMLSLicense" type="text" name='NMLSLicense' className={`form-control`} onChange={(e) => setNMLSLicense(e.target.value)} value={NMLSLicense}  />
                            </div>
                          </div>
                          <div className="cont">
                            <h3 className="title">ADD LICENSE</h3>
                            <div className="form-group">
                              <label>State<span className="rf">*</span></label>
                              <select id="licenseState" value={newLIcense.state} onChange={handleChange} className={`form-control ${inputErrors?.state && 'invalid-input'}`} onFocus={()=>{setInputsErrors({...inputErrors,state : null })}} name='state' >
                              <option value=''>Select</option>
                              {states.map((key, index) => (<option value={key}>{key}</option>))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>License Number</label>
                              <input id="licenseNumber" type="text" name='licenseNumber' value={newLIcense.licenseNumber} onChange={handleChange} className={`form-control ${inputErrors?.licenseNumber && 'invalid-input'}`} onFocus={()=>{setInputsErrors({...inputErrors,licenseNumber : null })}} />
                            </div>
                            <div className="form-group">
                              <label>Expiration date</label>
                              <input name='expirationDate'
                                value={newLIcense.expirationDate}
                                onChange={handleChange}
                                type="date"
                                className={`form-control ${inputErrors?.expirationDate && 'invalid-input'}`} onFocus={()=>{setInputsErrors({...inputErrors,expirationDate : null })}}
                                placeholder="mm-dd-yyyy"
                                min={getTodayDate()}
                                id="licenseExpiration"
                              />
                            </div>
                            <div className="form-group">
                            {buttonType==='add' ? (<button id="addLicense" type="button" onClick={handleAddLicense} className="fs_btn">
                                Add License
                              </button>) : <button id="updateLicense" type="button" onClick={handleUpdateLicense} className="fs_btn">
                                Update License
                              </button>}
                              
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="table-responsive">
                            <table className="table data_table">
                              <thead className="table-light">
                                <tr>
                                  <th width="10%">#</th>
                                  <th width="35%">State</th>
                                  <th width="25%">License number</th>
                                  <th width="20%">Expiration date</th>
                                  <th width="10">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                              {props.licenseData && props.licenseData.length > 0 && props.licenseData?.map((data, index) => {
                                  return (
                                    <tr>
                                      <td>{index+1}</td>
                                      <td>{data.state}</td>
                                      <td>{data.licenseNumber}</td>
                                      <td>{formatDate(data.expirationDate)}</td>
                                      <td>
                                        <div className="btn-group">
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={(e)=>handleEdit(index)}
                                            id={`editLicense-${index}`}
                                          >
                                            <span className="material-symbols-outlined icon">
                                              {" "}
                                              edit{" "}
                                            </span>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={()=>{setShowConfirmation(true); setEditIndex(index)} }
                                            id={`deleteLicense-${index}`}
                                          >
                                            <span className="material-symbols-outlined icon">
                                              {" "}
                                              delete{" "}
                                            </span>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="btn-group mt_20">
                        <button id="cancelLicense" onClick={(e) => {e.preventDefault(); handleNavigateCancel()}} className="border_btn mr_10">
                          Cancel
                        </button>
                        <button id="submitLicense" onClick={handleNext} className="fs_btn">
                        {props.isEdit ? 'Update' : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-sm-end d-none d-sm-block">
                © 2024 easystreetcapital.com. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default CreateUserStateLicenses;